<template>
  <div>
    <PageTitleVue> Cadastro de Funcionário </PageTitleVue>
    <ContainerVue>
      <Form :descriptionTitle="'Informações do funcionário'">
        <CreateButton @click="addEmployee" :icon="'fa-solid fa-user-plus'" />
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import Form from '../layout/Form.vue'
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import ContainerVue from '@/components/Container.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import { storeApp } from '@/store'
import EmployeesService from './../services/employees.service'
import { dataEmployee } from '../store'
import { onMounted } from 'vue'
import { useToast } from 'vue-toastification'
import * as yup from './../../../validator'
import { cleanFormField, setFormFieldsError } from '@/helpers/formFieldsHandler'

const toast = useToast()
const appStore = storeApp.state
const store = dataEmployee

const schema = yup.object().shape({
  employee: yup.object().shape({
    name: yup.string().required().label('Nome').min(5),
    function: yup.string().required().label('Função'),
    registeredSince: yup.string().nullable(),
    phone: yup.string().required().label('Celular'),
    document: yup.object().shape({
      cpf: yup.string().label('CPF').nullable().required(),
      cnh: yup.string().label('CNH').nullable().max(6)
    }),
    address: yup.object().shape({
      city: yup.string().label('Cidade').required().min(1),
      country: yup.string().label('Bairro').required().min(1)
    })
  })
})

onMounted(() => {
  store.commit('reset')
})

async function addEmployee () {
  appStore.loading = true

  const errors = []
  await schema.validate(store.state, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message
      })
    })
  })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    console.log(errors, store.state.employee);
    
    return
  }

  if (
    store.state.employee.photo &&
    typeof store.state.employee.photo === 'object' &&
    typeof store.state.employee.imgPath === 'string'
  ) {
    await EmployeesService.editImage(
      store.state.employee.photo,
      store.state.employee.imgPath
    )
      .then((res) => {
        store.state.employee.imgPath = res.data
        store.state.employee.photo = res.data
      })
      .catch((err) =>
        toast('Algo deu errado ao subir arquivo!', {
          toastClassName: 'toast-warning'
        })
      )
  } else if (
    store.state.employee.photo &&
    typeof store.state.employee.photo !== 'string'
  ) {
    await EmployeesService.saveImage(store.state.employee.photo)
      .then((res) => {
        store.state.employee.imgPath = res.data
        store.state.employee.photo = res.data
      })
      .catch((err) =>
        toast('Algo deu errado ao subir arquivo!', {
          toastClassName: 'toast-warning'
        })
      )
  }

  store.state.employee.company_id = appStore.accessToken.getItem('__company')

  await EmployeesService.register(store.state.employee)
    .then((res) => {
      appStore.loading = false
      if (res.data.success) {
        toast(res.data.msg, { toastClassName: 'toast-success' })
      }
    })
    .catch((err) => {
      appStore.loading = false
      toast('Algo deu errado! Tente novamente mais tarde', {
        toastClassName: 'toast-error'
      })
    })
}
</script>

<style></style>
